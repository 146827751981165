import React from 'react'
import SVG from 'react-inlinesvg'

import PageHeader from '@c/page-header'

import iconNetwork from '@assets/meetup/icon-network.svg'
import iconCoffee from '@assets/meetup/icon-coffee.svg'
import ProjectImage from '@/components/project-image'

const MeetupSchedule = ({
  title,
  firstEvent,
  firstEventHour,
  secondEventHour,
  speaker1Image,
  speaker1Name,
  speaker1Company,
  speaker1TalkDescription,
  thirdEvent,
  thirdEventHour,
  fourthEventHour,
  speaker2Image,
  speaker2Name,
  speaker2Company,
  speaker2TalkDescription
}) => {
  return (
    <section
      id="meetup-schedule"
      className="meetup-schedule ta-center pt-160 bg-soft-blue"
    >
      <div className="container pt-100">
        <PageHeader type="h2" title={title} />

        <div className="row mt-40 meetup-schedule-table">
          {/* 1 */}
          <div className="meetup-schedule-network bg-meetup c-white d-flex align-items-center justify-content-center">
            <p className="h2">{firstEventHour}</p>
          </div>
          {/* 2 */}
          <div className="bg-white d-flex align-items-center justify-content-center">
            <div>
              <SVG src={iconNetwork} />
              <p className="fw-text-bold mt-16">{firstEvent}</p>
            </div>
          </div>
          {/* 3 */}
          <div className="bg-dark c-white ta-left">
            <div>
              <p className="h2 time ta-right">{secondEventHour}</p>

              {speaker1Image && (
                <ProjectImage className="mt-8" image={speaker1Image} />
              )}

              <h4 className="mt-8">{speaker1Name}</h4>
              <p className="mt-8 o-60">{speaker1Company}</p>

              <p className="mt-16">{speaker1TalkDescription}</p>
            </div>
          </div>
          {/* 4 */}
          <div className="meetup-schedule-coffee-logo bg-white d-flex align-items-center justify-content-center">
            <div>
              <SVG src={iconCoffee} />
              <p className="fw-text-bold mt-16">{thirdEvent}</p>
            </div>
          </div>
          {/* 5 */}
          <div className="meetup-schedule-coffee bg-meetup c-white d-flex align-items-center justify-content-center">
            <p className="h2">{thirdEventHour}</p>
          </div>
          {/* 6 */}
          <div className="bg-dark c-white ta-left">
            <div>
              <p className="h2 time ta-right">{fourthEventHour}</p>

              {speaker2Image && (
                <ProjectImage className="mt-8" image={speaker2Image} />
              )}

              <h4 className="mt-8">{speaker2Name}</h4>
              <p className="mt-8 o-60">{speaker2Company}</p>

              <p className="mt-16">{speaker2TalkDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MeetupSchedule
