import React from 'react'

const MeetupRegister = ({
  title,
  description,
  registerButtonText,
  registerLink
}) => {
  return (
    <section
      id="meetup-register"
      className="meetup-register ta-center py-100 py-lg-160"
    >
      <div className="container">
        <h2>{title}</h2>
        <p className="fs-large mt-16">{description}</p>
        <a
          href={registerLink}
          className="btn bg-meetup c-white btn--round fs-x-large px-40 py-24 mt-40 mr-16"
        >
          {registerButtonText}
        </a>
      </div>
    </section>
  )
}

export default MeetupRegister
