import React from 'react'

import PageHeader from '@c/page-header'
import SpeakerCard from '@c/speaker-card'

const MeetupSpeaker = ({ title, speakers }) => {
  return (
    <section
      id="meetup-speaker"
      className="meetup-speaker ta-center pt-100 pt-lg-120"
    >
      <div className="container">
        <PageHeader type="h2" title={title} />

        <div className="row justify-content-center mt-40">
          {speakers.map(speaker => (
            <div key={speaker.id} className="col-6 col-lg-4 mb-32">
              <SpeakerCard
                name={speaker.name}
                title={speaker.title}
                speakHour={speaker.talkHour}
                photo={speaker.image}
                photoAlt={speaker.image?.alt}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default MeetupSpeaker
