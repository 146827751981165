import React from 'react'
import { graphql } from 'gatsby'

import SEO from '@c/seo'

import MeetupFooter from '@v/meetup/footer'
import MeetupRegister from '@v/meetup/register'
import MeetupSponsor from '@v/meetup/sponsor'
import MeetupSpeaker from '@v/meetup/speaker'
import MeetupSchedule from '@v/meetup/schedule'
import MeetupHero from '@v/meetup/hero'
import MeetupHeader from '@v/meetup/header'

import './index.css'
import DatoCMSModel from '../../models/DatoCMSModel'

const Page = ({ data }) => {
  const { page } = data
  const {
    header: [header],
    hero: [hero],
    schedule: [schedule],
    speakers,
    register: [register],
    footer: [footer],
    locale
  } = page
  const model = new DatoCMSModel(page)

  return (
    <div className="meetup">
      <SEO {...model.getSeoComponentProps()} />

      {header && <MeetupHeader {...header} locale={locale} />}

      {hero && <MeetupHero locale={page.locale} {...hero} />}

      {schedule && <MeetupSchedule {...schedule} />}

      {speakers && (
        <MeetupSpeaker title={page.speakersTitle} speakers={speakers} />
      )}

      {page.sponsors && (
        <MeetupSponsor title={page.sponsorsTitle} sponsors={page.sponsors} />
      )}

      {register && <MeetupRegister {...register} />}

      {footer && (
        <MeetupFooter {...footer} locale={page.locale} model={model} />
      )}
    </div>
  )
}

export default Page

export const query = graphql`
  query MeetupPageQuery($locale: String) {
    page: datoCmsMeetupPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      slug

      header {
        logo {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
        meetupScheduleLinkText
        speakersLinkText
        registerButtonText
      }

      hero {
        titleLong
        titleShort
        registerButtonText
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
        imageMobile {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
        eventDate
      }

      schedule {
        title
        firstEvent
        firstEventHour

        secondEventHour
        speaker1Image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 70)
              }
            }
          }
        }

        speaker1Name
        speaker1Company
        speaker1TalkDescription

        thirdEvent
        thirdEventHour

        fourthEventHour
        speaker2Image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 70)
              }
            }
          }
        }
        speaker2Name
        speaker2Company
        speaker2TalkDescription
      }

      speakersTitle
      speakers {
        id
        name
        title
        talkHour
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }

      sponsorsTitle
      sponsors {
        originalId
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }

      register {
        title
        description
        registerButtonText
        registerLink
      }

      footer {
        bootstrapUrlKey
        contactEmailAddress
        eMailAddressLabel
        latitude
        longitude
        pinHeight
        phoneNumber
        contactUsText
        copyrightTitleSmall
        copyrightTitleBig
        submitButtonText
        submitSuccessMessage
        submitFailMessage
        newsletterSubmitUrl
      }
    }
  }
`
