import React from 'react'
import { DateTime } from 'luxon'
import ProjectImage from '@/components/project-image'

const MeetupHero = ({
  locale,
  titleLong,
  titleShort,
  registerButtonText,
  image,
  imageMobile,
  eventDate
}) => {
  const eventDateLuxon = DateTime.fromISO(eventDate)
  let minute = eventDateLuxon.minute
  if (minute.toString().length === 1) {
    minute = '0' + minute
  }
  return (
    <section id="meetup-hero" className="meetup-hero ta-center pt-60 pt-lg-100">
      <div className="container">
        {/* title */}
        <div className="row">
          <div className="col-10 offset-1 col-lg-8 offset-lg-2">
            <h1>
              <span className="d-none d-lg-block">{titleLong}</span>
              <span className="d-lg-none">{titleShort}</span>
            </h1>
            <a
              href="#meetup-register"
              className="btn btn--red btn--round d-lg-none mt-40"
            >
              {registerButtonText}
            </a>
          </div>
        </div>

        {/* date */}
        <div className="meetup-datetime mt-160 mt-lg-120">
          <ProjectImage
            className="d-lg-none meetup-datetime-image meetup-datetime-image-mobile"
            image={imageMobile}
          />
          <ProjectImage
            className="d-none d-lg-block meetup-datetime-image meetup-datetime-image-desktop"
            image={image}
          />
          <div className="meetup-datetime-date bg-meetup">
            <div className="day">{eventDateLuxon.day}</div>
            <div className="month">
              {eventDateLuxon.toLocaleString({ month: 'long', locale })}
            </div>
          </div>
          <div className="meetup-datetime-time">
            {eventDateLuxon.hour}:{minute}
          </div>
        </div>
      </div>
    </section>
  )
}

export default MeetupHero
