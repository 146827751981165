import React, { useCallback, useState } from 'react'
import FormInput from '@c/form-input'
import forIn from 'lodash/forIn'
import { useForm } from 'react-hook-form'
import Modal from '@c/modal'
import useViewport from '@h/use-viewport'
import ShareBox from '@c/share-box'
// import Map from '@c/map'
const GATSBY_RESOURCES_SUBSCRIBE_FORM_POST_URL =
  process.env.GATSBY_RESOURCES_SUBSCRIBE_FORM_POST_URL

const MeetupFooter = ({
  // bootstrapUrlKey,
  contactEmailAddress = '',
  colorClass = 'bg-meetup',
  // latitude,
  // longitude,
  // locale,
  // pinHeight,
  phoneNumber = '',
  contactUsText,
  copyrightTitleSmall,
  copyrightTitleBig,
  submitSuccessMessage,
  submitFailMessage,
  eMailAddressLabel,
  submitButtonText,
  model
}) => {
  const form = useForm()
  const [state, setState] = useState(() => ({
    modalContent: '',
    modalToggle: 'd-none'
  }))
  const onSubscribe = useCallback(
    e => {
      e.preventDefault()
      const url = GATSBY_RESOURCES_SUBSCRIBE_FORM_POST_URL
      const formData = new FormData()

      forIn(form.getValues(), (value, key) => formData.append(key, value))
      setState({ modalToggle: 'modal', modalContent: 'Please Waiting..' })

      fetch(url, {
        method: 'post',
        body: formData
      })
        .then(async response => ({ response, body: await response.text() }))
        .then(() => {
          setState({ modalToggle: 'd-none' })
        })
        .then(() => {
          form.reset()
          setState({ modalToggle: 'modal', modalContent: submitSuccessMessage })
        })
        .catch(() => {
          setState({ modalToggle: 'modal', modalContent: submitFailMessage })
        })
        .finally(() => {
          setTimeout(() => {
            setState({ modalToggle: 'd-none' })
          }, 2000)
        })
    },
    [form]
  )

  const title = model.getTitle()
  const url = `https://zeo.org${model.getPath()}`
  const facebookLink = 'https://www.facebook.com/digitalzonebyzeo'
  const instagramLink = 'https://www.instagram.com/zeoagency'
  const twitterLink = 'https://twitter.com/digitalzoned'
  const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    url
  )}`
  const mailLink = `mailto:?subject=${encodeURIComponent(
    title
  )}&body=${encodeURIComponent(url)}`
  const { width } = useViewport()

  return (
    <footer id="meetup-footer" className="meetup-footer ta-center ta-lg-left">
      {/*
      <Map
        bootstrapUrlKey={bootstrapUrlKey}
        locale={locale}
      >
        <img
          lat={latitude}
          lng={longitude}
          height={pinHeight}
          style={{ marginTop: -60, marginLeft: -23 }}
          src="/assets/meetup/pin.svg"
          alt=""
        />
      </Map>
    */}

      <div className={`row-top ${colorClass}  py-40 py-lg-0`}>
        <div className="container px-0">
          <div className="row no-gutters">
            <div className="col-12 col-lg-6 c-white px-40 pl-lg-0">
              <a className="h2" href={`mailto:${contactEmailAddress}`}>
                <span className="fw-head-normal">
                  {contactEmailAddress.slice(
                    0,
                    contactEmailAddress.indexOf('@')
                  )}
                </span>
                {contactEmailAddress.slice(contactEmailAddress.indexOf('@'))}
              </a>
              <a
                className="h4 fw-head-normal mt-8 o-60 mb-16 mb-lg-0"
                href={`tel:${phoneNumber.replace(/\s/g, '')}`}
              >
                {phoneNumber}
              </a>
            </div>

            <div className="col-12 col-lg-6 flex-row align-items-center px-40 pr-lg-0 ta-center ta-lg-left ">
              <div className="row justify-content-center">
                <div className="col-12 col-lg">
                  <p className="h3 mb-8 mb-lg-0 mr-lg-32  info-text">
                    {contactUsText}
                  </p>
                </div>
                <div className="col-auto">
                  <ShareBox
                    className={`${colorClass}`}
                    direction={width <= 992 ? 'h' : 'v'}
                    facebookLink={facebookLink}
                    instagramLink={instagramLink}
                    twitterLink={twitterLink}
                    mailLink={mailLink}
                    linkedinLink={linkedinLink}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row-bottom">
        <div className="container px-0">
          <div className="row no-gutters">
            <div className="col-12 col-lg-6 order-lg-1 bg-soft-blue px-40 pr-lg-0">
              <form
                className="row justify-content-center my-32 my-lg-0 align-items-center"
                onSubmit={onSubscribe.bind(this)}
              >
                <div className="col-11 col-lg-9 mb-16">
                  <FormInput
                    type="email"
                    name="email"
                    placeholder={eMailAddressLabel}
                    required={true}
                    ref={form.register()}
                  />
                </div>
                <div className="d-block d-lg-none col-12 ma-0"></div>
                <button
                  className="btn btn--red bg-dark btn--round col-auto py-16 mb-lg-16"
                  type="submit"
                >
                  {submitButtonText}
                </button>
              </form>
            </div>
            <div className="col-12 col-lg-6 px-40 pl-lg-0 py-8 py-lg-0">
              <p className="h4">{copyrightTitleBig}</p>
              <p className="h4 fw-head-normal mt-8">{copyrightTitleSmall}</p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        modalToggle={state.modalToggle}
        modalContent={state.modalContent}
      />
    </footer>
  )
}

export default MeetupFooter
