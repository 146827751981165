import React from 'react'

import PageHeader from '@c/page-header'
import SponsorCard from '@c/sponsor-card'

const MeetupSponsor = ({ title, sponsors }) => {
  return (
    <section
      id="meetup-sponsor"
      className="meetup-sponsor ta-center pt-100 pt-lg-120"
    >
      <div className="container">
        <PageHeader type="h2" title={title} />

        <div className="row justify-content-center mt-40">
          {sponsors.map(sponsor => (
            <div key={sponsor.originalId} className="col-6 col-lg-4 mb-32">
              <SponsorCard image={sponsor} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default MeetupSponsor
