import React from 'react'
import { Link } from 'gatsby'
import makeSlug from '@/utils/make-slug'
import ProjectImage from '@/components/project-image'
const MeetupHeader = ({
  locale,
  logo,
  meetupScheduleLinkText,
  speakersLinkText,
  registerButtonText
}) => {
  return (
    <header className="p-32 ta-center">
      <div className="container d-flex align-items-center justify-content-center justify-content-lg-start">
        {/* logo */}
        <div className="logo">
          <Link to={makeSlug.generic(locale)}>
            <ProjectImage image={logo} />
          </Link>
        </div>

        {/* nav */}
        <nav className="d-none d-lg-block ml-auto">
          <a href="#meetup-schedule" className="link-hover-meetup">
            {meetupScheduleLinkText}
          </a>
          <a href="#meetup-speaker" className="link-hover-meetup ml-24">
            {speakersLinkText}
          </a>
          <a href="#meetup-register" className="btn btn--dark btn--round ml-24">
            {registerButtonText}
          </a>
        </nav>
      </div>
    </header>
  )
}

export default MeetupHeader
